import { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { StyledTextField } from './components';
import type { UncontrolledInputProps } from './interfaces';


const UncontrolledInput = forwardRef<{ getValue: () => string | undefined }, UncontrolledInputProps>(({ initialValue }, ref) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useImperativeHandle(ref, () => ({
    getValue: () => inputRef.current?.value
  }));

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = initialValue;
    }
  }, [initialValue]);

  return <StyledTextField multiline inputRef={inputRef} />;
});

export default UncontrolledInput;