import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, Toolbar, AppBar } from "@mui/material";
import { WelcomeName, SignInSignOutButton } from '../../components';

const NavBar = () => {
  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar
          sx={{
            flexDirection: 'row-reverse',
            marginLeft: '40px',
            marginRight: '40px'
          }}
        >
          <WelcomeName />
          <SignInSignOutButton />
          <Typography style={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to="/"
              color="inherit"
              variant="h6"
            >
              Delphi AI
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;