import { type FC, type PropsWithChildren } from "react";
import { NavBar } from "../../components";


const PageLayout: FC<PropsWithChildren> = ({ children}) => {
  return (
    <>
      <NavBar />
      {/* <Typography variant="h5" align="center">Welcome to the Microsoft Authentication Library For React Quickstart</Typography> */}
      <br/>
      <br/>
      {children}
    </>
  );
};

export default PageLayout;