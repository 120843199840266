import { type FC, useRef, useState } from "react";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledInput } from "../../components";
import { StyledHeader, StyledText } from "./components";
import type { Label } from "../../types";
import type { FoundQuestionProps } from "./interfaces";

const FoundQuestion: FC<FoundQuestionProps> = ({ header, annotation, onEdit }) => {
  const inputRef = useRef<{ getValue: () => string | undefined }>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);

  const onSaveAnnotation = (annotation: Label) => {
    if (inputRef.current) {
      const inputValue = inputRef.current.getValue();

      onEdit({ ...annotation, question: inputValue });
    }

    setIsEditing(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingTop: '10px',
          borderTop: '1px solid #000'
        }}
      >
        <StyledHeader variant="body1" bgcolor={annotation.color}>
          {header}
        </StyledHeader>
        <Box>
          <Tooltip title="Edit">
            <IconButton aria-label="edit" onClick={startEditing} sx={{ position: 'relative', top: '-5px' }}>
              <FontAwesomeIcon icon={faPenToSquare} size="xs" color={'rgb(30, 48, 80)'} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ display: isEditing ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'flex-start' }}>
        <UncontrolledInput ref={inputRef} initialValue={annotation.question ? annotation.question : 'No question found'} />
        <Button
          variant="contained"
          onClick={() => onSaveAnnotation(annotation)}
          sx={{ p: 0, position: 'relative', top: '-2px', maxWidth: '100px', marginTop: '10px' }}
        >
          Save
        </Button>
      </Box>
      <StyledText variant="body1" bgcolor={annotation.color} sx={{ display: isEditing ? 'none' : 'initial' }}>
        {annotation.question}
      </StyledText>
    </>
  );
};

export default FoundQuestion;