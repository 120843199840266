import { type FC, useRef, useEffect } from 'react';
import { getContrastColor } from '../../utils';
import type { Label, Category } from '../../types';
import type { TextAnnotatorProps } from "./interfaces";
import './styles.css';

const TextAnnotator: FC<TextAnnotatorProps> = ({
  text,
  labels,
  textDisplay,
  selectedCategory,
  getQuestionFromText,
  onUpdateLabels,
  scrollToAnnotation
}) => {
  const textContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    renderAnnotations();
  }, [labels, text]);

  const handleMouseUp = () => {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0 && selectedCategory) {
      const range = selection.getRangeAt(0);

      if (range && range.toString().length > 0) {
        addAnnotation(range, selectedCategory);
      }
    }
  };

  const addAnnotation = (range: Range, category: Category) => {
    const startOffset = getGlobalOffset(range.startContainer, range.startOffset);
    const endOffset = getGlobalOffset(range.endContainer, range.endOffset);
    const selectedText = range.toString();

    const overlap = labels.some(label => 
      (startOffset >= label.start && startOffset < label.end) || 
      (endOffset > label.start && endOffset <= label.end) || 
      (startOffset <= label.start && endOffset >= label.end)
    );

    if (overlap) {
      return;
    }

    const newAnnotation: Label = {
      labelId: `${category.labelName}-${Date.now()}`,
      label: category.labelName,
      start: startOffset,
      end: endOffset,
      color: category.color,
      text: selectedText,
    };

    const updatedAnnotations = [...labels, newAnnotation].map(annotation => {
      if (annotation.start >= newAnnotation.end) {
        return {
          ...annotation,
          start: annotation.start + newAnnotation.label.length,
          end: annotation.end + newAnnotation.label.length
        };
      }

      return annotation;
    });

    getQuestionFromText(newAnnotation.text, newAnnotation)
    onUpdateLabels(updatedAnnotations);
  };

  const getGlobalOffset = (node: Node, offset: number): number => {
    let globalOffset = 0;
    let current = node;

    while (current && current !== textContainerRef.current) {
      if (current.previousSibling) {
        current = current.previousSibling;
        globalOffset += (current.textContent?.length || 0);
      } else {
        current = current.parentNode;
      }
    }

    return globalOffset + offset;
  };

  const renderAnnotations = () => {
    if (textContainerRef.current) {
      textContainerRef.current.innerHTML = '';

      let currentPos = 0;
      const sortedAnnotations = [...labels].sort((a, b) => a.start - b.start);

      sortedAnnotations.forEach((annotation) => {
        let start = -1, end = -1;
        const { color, label, text: context } = annotation;
        const regexp = /(\r\n|\r|\n)+| |●|	|&|\.|,|-|‎|;/g;
        const formattedContext = context.replace(regexp, '');

        for (let i = 0; i < text.length; i++) {
          if (!text[i].match(regexp) && text[i] === formattedContext[0]) {
            let k = 0; // Ignored symbols counter
            start = i;

            for (let j = 0; j < formattedContext.length;) {
              if (text[i + j + k].match(regexp)) {
                k++
              } else if (text[i + j + k] === formattedContext[j]) {
                j++;
              } else {
                start = -1;
                break;
              }
            }

            if (start !== -1) {
              end = formattedContext.length + i + k;
              break;
            }
          }
        }

        if (start === -1) {
          return;
        }

        const sentence = text.slice(start, end);

        if (textContainerRef.current.textContent.includes(sentence)) {
          return;
        }


        if (currentPos < start) {
          const beforeText = text.slice(currentPos, start);
          const textNode = document.createTextNode(beforeText);

          textContainerRef.current?.appendChild(textNode);
        }

        const mark = document.createElement('mark');
        mark.className = 'annotation';
        mark.style.backgroundColor = color;
        mark.style.color = getContrastColor(color);
        mark.onclick = () => scrollToAnnotation(annotation);

        const span = document.createElement('span');
        span.className = 'label';
        span.style.backgroundColor = color;
        span.style.color = getContrastColor(color);
        span.innerText = label;

        const annotationTextNode = document.createTextNode(sentence);
        mark.appendChild(annotationTextNode);
        mark.appendChild(span);
        textContainerRef.current?.appendChild(mark);

        currentPos = end;
      });

      if (currentPos < text.length) {
        const remainingText = document.createTextNode(text.slice(currentPos));

        textContainerRef.current?.appendChild(remainingText);
      }
    }
  };

  return (
    <div ref={textContainerRef} style={{ whiteSpace: textDisplay }} onMouseUp={handleMouseUp}>
      {text}
    </div>
  );
};

export default TextAnnotator;
export { default as LabelSelector } from './LabelSelector';
export { default as AnnotationList } from './AnnotationList';
