import { styled } from "@mui/system";
import { Button, Typography, Container, Box, ThemeProvider } from "@mui/material";
import { loginRequest } from "../authConfig";
import { theme } from "../styles/theme";
import {useEffect} from "react";

interface LogInProps {
  instance: {
    loginPopup: (request: typeof loginRequest) => void;
  };
}

const StyledBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  backgroundColor: theme.palette.primary.main
}));

const StyledContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
});

const StyledTypography = styled(Typography)({
  color: "#FFF"
});

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontSize: 20,
  backgroundColor: "#FFF",
  color: 'rgb(7, 26, 67)',
  minHeight: '48px',
  padding: '8px 16px',
  marginTop: '40px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#f0f0f0'
  }
}));

export function LogIn({ instance }: LogInProps) {
  const handleLogin = () => {
    instance.loginPopup(loginRequest);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledBox>
        <StyledContainer>
          <StyledTypography variant="h4">
            DelphiAI Assistant
          </StyledTypography>
          <StyledButton onClick={handleLogin} variant="contained" size="large">
            Log in
          </StyledButton>
        </StyledContainer>
      </StyledBox>
    </ThemeProvider>
  );
}
