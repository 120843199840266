import { ListItem, ListItemText, ListItemAvatar, Avatar } from "@mui/material";
import { Person, Work, Mail, Phone, LocationOn } from "@mui/icons-material";

export const NameListItem: React.FC<{name: string}> = ({name}) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <Person />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Name" secondary={name}/>
  </ListItem>
);

export const JobTitleListItem: React.FC<{jobTitle: string}> = ({jobTitle}) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <Work />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Title" secondary={jobTitle}/>
  </ListItem>
);

export const MailListItem: React.FC<{mail: string}> = ({mail}) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <Mail />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Mail" secondary={mail}/>
  </ListItem>
);

export const PhoneListItem: React.FC<{phone: string}> = ({phone}) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <Phone />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Phone" secondary={phone}/>
  </ListItem>
);

export const LocationListItem: React.FC<{location: string}> = ({location}) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>
        <LocationOn />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Location" secondary={location}/>
  </ListItem>
);