import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DocumentSlice {
  documentPreview: string;
  documentContent: string;
  documentName: string;
  documentSourceType: string;
}

const initialState: DocumentSlice = {
  documentPreview: '',
  documentContent: '',
  documentName: '',
  documentSourceType: 'Sharepoint'
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setDocumentPreview(state, action) {
      state.documentPreview = action.payload.documentPreview;
      state.documentName = action.payload.documentName;
    },
    setDocumentContent(state, action) {
      state.documentContent = action.payload;
    },
    setDocumentSourceType(state, action) {
      state.documentSourceType = action.payload;
    },
    clearDocumentPreview(state) {
      state.documentPreview = '';
      state.documentName = '';
    },
    clearDocumentContent(state) {
      state.documentContent = '';
    },
  },
});

export const {
  setDocumentPreview,
  setDocumentContent,
  setDocumentSourceType,
  clearDocumentPreview,
  clearDocumentContent
} = documentSlice.actions;

export default documentSlice.reducer;
