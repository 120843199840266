import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { IconButton, MenuItem, Menu } from '@mui/material';
import { AccountCircle } from "@mui/icons-material";
import { setUserAccount } from "../../store";
import { useDispatch } from "react-redux";

const SignOutButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  const handleLogout = (logoutType: string) => {
    setAnchorEl(null);

    if (logoutType === "popup") {
      dispatch(setUserAccount({ account: null }))
      instance.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else if (logoutType === "redirect") {
      dispatch(setUserAccount({ account: null }))
      instance.logoutRedirect();
    }
  }

  return (
    <div>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleLogout("popup")} key="logoutPopup">Logout using Popup</MenuItem>
        <MenuItem onClick={() => handleLogout("redirect")} key="logoutRedirect">Logout using Redirect</MenuItem>
      </Menu>
    </div>
  )
};

export default SignOutButton;