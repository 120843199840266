import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Label } from '../../types';

interface AnnotationState {
  annotations: Label[];
  loadFrom: string;
  questionLoadError: boolean;
}

const initialState: AnnotationState = {
  annotations: [],
  loadFrom: 'server',
  questionLoadError: false,
};

const annotationSlice = createSlice({
  name: 'annotator',
  initialState,
  reducers: {
    setAnnotations(state, action: PayloadAction<Label[]>) {
      state.annotations = [...action.payload];
    },
    removeAnnotations(state) {
      state.annotations = [];
    },
    updateAnnotation(state, action: PayloadAction<Label>) {
      const newAnnotations = state.annotations.filter(item => item.labelId !== action.payload.labelId);
      state.annotations = [...newAnnotations, action.payload];
    },
    deleteAnnotation(state, action: PayloadAction<Label>) {
      const newAnnotations = state.annotations.filter(item => item.labelId !== action.payload.labelId);
      state.annotations = [...newAnnotations];
    }, 
    setLoadFrom(state, action: PayloadAction<string>) {
      state.loadFrom = action.payload
    },
    setQuestionLoadError(state, action: PayloadAction<boolean>) {
      state.questionLoadError = action.payload
    }
  },
});

export const {
  setAnnotations,
  removeAnnotations,
  updateAnnotation,
  setLoadFrom,
  setQuestionLoadError
} = annotationSlice.actions;

export default annotationSlice.reducer;
