import { FC, PropsWithChildren, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import type { ToolbarAccordionProps } from "./interfaces";


const ToolbarAccordion: FC<PropsWithChildren<ToolbarAccordionProps>> = ({ label, children }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleAccordion = () => {
    setOpen(!open);
  }

  return (
    <Accordion
      disableGutters
      expanded={open}
      square={true}
      onChange={toggleAccordion}
      sx={{
        fontFamily: 'sans-serif',
        margin: '0 0 5px'
      }}
    >
      <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ExpandMore />}>
        {label}
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default ToolbarAccordion;
