import { type FC, type ChangeEvent, useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import type { LabelSelectorProps } from "./interfaces";

const LabelSelector: FC<LabelSelectorProps> = ({ categories, onSelect }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedLabel = (event.target as HTMLInputElement).value;
    const selected = categories.find(category => category.labelName === selectedLabel);

    setSelectedCategory(selectedLabel);

    if (selected) {
      onSelect(selected);
    }
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup value={selectedCategory} onChange={handleChange}>
        {categories.map(category => (
          <FormControlLabel
            key={category.categoryId}
            value={category.labelName}
            label={category.labelName}
            control={
              <Radio
                disableRipple
                sx={{
                  color: category.color,
                  '&.Mui-checked': {
                    color: category.color,
                  }
                }}
              />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default LabelSelector;
