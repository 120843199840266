import React, { type FC, useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAccount } from "@azure/msal-react";
import { v4 as uuidv4 } from "uuid";
import { Container, Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { type RootState, setLoadFrom, addNewNotification, setAnnotations } from '../store';
import type { Label } from '../types';
import { getAccountInfo } from "../utils";
import { msalInstance } from "../index";
import {
  addEntityToTable,
  addMessageToQueue,
  deleteEntityFromTable,
  getEntityFromTable,
  uploadDataToBlob
} from "../api/queueApi";
import { getSasTokenUriForBlob, getSasTokenUriForQueue, getSasTokenUriForTableEntity } from "../api";
import { azureContainerName, azureFunctionQueueCode, azureFunctionBlobCode, azureFunctionTableCode, azureTableName, maxAttempts, timeoutDelay } from "../constants";
import { Preloader } from "../components";


interface QuestionAnswersProps {
  annotation: Label;
  index: number;
}

const StickyHeader = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  position: 'sticky',
  top: 0,
  background: '#FFFFFF',
  padding: '10px 0',
  zIndex: 5
}))

const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: 0,
}))

const OuterContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledSourceBadge = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  background: 'rgb(7, 26, 67)',
  color: '#FFF',
  padding: '4px 10px',
  bottom: '-1px',
  left: '-1px',
}));

export const ReviewFinal: React.FC = () => {
  const account = useAccount();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const annotations = useSelector((state: RootState) => state.annotator.annotations);

  const [extractLoading, setExtractLoading] = useState(false);
  const mounted = useRef(false);

  const FileName = searchParams.get('FileName');
  const SiteId = searchParams.get('SiteId');
  const DriveId = searchParams.get('DriveId');
  const ItemId = searchParams.get('ItemId');

  const fileName = FileName.substring(0, FileName.lastIndexOf('.'));
  const fileExtension = FileName.substring(FileName.lastIndexOf('.'));
  const timestamp = new Date();
  const regex = /:/g;
  const formattedTimestamp = timestamp.toISOString().slice(0,-5).replace(regex, '-');
  const generatedFileName = `${fileName}_DelphiAI Generated_${account?.username}_${formattedTimestamp}${fileExtension}`;
  const sortedAnnotations = [...annotations].sort((a, b) => a.start - b.start)

  useEffect(() => {
    mounted.current = true;
    dispatch(setLoadFrom('store'));

    if (!annotations.length) {
      const annotations = JSON.parse(localStorage.getItem('annotations'));

      dispatch(setAnnotations(annotations));
    }

    return () => {
      mounted.current = false;
    }
  }, [])

  const goToAnnotator = () => {
    const params = new URLSearchParams();
    params.append('FileName', FileName);
    params.append('SiteId', SiteId);
    params.append('DriveId', DriveId);
    params.append('ItemId', ItemId);
    
    dispatch(setLoadFrom('store'));

    navigate({
      pathname: '/annotator',
      search: params.toString()
    });
  };

  const selectedAnswers = (answers: any[], labelId: string) => {
    if (answers?.length === 0 || !answers) {
      return (
        <Box
          sx={{
            width: '100%',
            marginBottom: '15px',
            border: '1px solid black',
            padding: '10px',
            boxSizing: 'border-box'
          }}>
          <Typography key={labelId}>No answers were found</Typography>
        </Box>
      )
    }
    if (answers?.every(item => item.selected === false)) {
      return (
        <Box
          sx={{
            width: '100%',
            marginBottom: '15px',
            border: '1px solid black',
            padding: '10px',
            boxSizing: 'border-box'
          }}>
          <Typography key={labelId}>No answers were selected</Typography>
        </Box>
      )
    }

    return (
      <>
        {answers?.map((item, index) => {
          if (item.selected) {
            return (
              <Box
                key={`${labelId}${index}`}
                sx={{
                  position: 'relative',
                  width: '100%',
                  marginBottom: '15px',
                  border: '1px solid black',
                  padding: '10px 10px 40px',
                  boxSizing: 'border-box'
                }}>
                <Typography>{item.answer}</Typography>
                <StyledSourceBadge>{`Source: ${item.badge}`}</StyledSourceBadge>
              </Box>
            )
          }
        })}
      </>
    )
  }

  const QuestionAnswerComponent: FC<QuestionAnswersProps> = ({ annotation, index }) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: '30px 0' }}>
        <Box sx={{ borderBottom: '1px solid black', marginBottom: '10px' }}>
          <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{`${annotation.label} #${index + 1}`}</Typography>
          <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Context:</Typography>
          <Typography>{annotation.text}</Typography>
        </Box>
        <Box sx={{ marginBottom: '10px' }}>
          <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Question:</Typography>
          <Typography sx={{ fontWeight: 'bold' }}>{annotation.question}</Typography>
        </Box>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Answers:</Typography>
        {selectedAnswers(annotation.answers, annotation.labelId)}
      </Box>
    )
  }

  const onExtract = async () => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const uuid = uuidv4();
    const queueName = 'save-data-into-document';
    let attempt = 0;

    setExtractLoading(true);

    const blob = {
      FileName: generatedFileName,
      AccessToken: account.accessToken,
      ContentItems: annotations.map((item) => {
        const answers = item.answers?.filter((answer) => answer.selected) ?? [];

        return {
          Question: item.question,
          Context: item.text,
          Answers: answers.map(({ answer }) => answer)
        };
      }),
    };
    const blobSasToken = await getSasTokenUriForBlob(azureContainerName, uuid, azureFunctionBlobCode, account.idToken);
    uploadDataToBlob(azureContainerName, uuid, blob, blobSasToken);


    const tableItem = {
      partitionKey: queueName,
      rowKey: uuid,
      Body: uuid,
    };
    const tableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, uuid, queueName, account.idToken);
    addEntityToTable(tableItem, tableSasToken);


    const message = {
      TablePartitionKey: queueName,
      TableRowKey: uuid,
    };
    const queueSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, queueName, account.idToken);
    addMessageToQueue(queueName, JSON.stringify(message), queueSasToken);

    while (attempt < maxAttempts && mounted.current) {
      try {
        const res = await getEntityFromTable(tableItem.partitionKey, tableItem.rowKey, tableSasToken);

        if (res.ResponseErrorMessage) {
          setExtractLoading(false);
          dispatch(addNewNotification('Something went wrong please try again'));
          deleteEntityFromTable(tableItem.partitionKey, tableItem.rowKey, tableSasToken);
          break;
        }

        if (res && res.Response) {
          const fileUrl = res.Response;
          const notification = (
            <div>
              <p>File successfully uploaded <a href={fileUrl} target="_blank">Open file</a></p>
            </div>
          );
          
          setExtractLoading(false);

          window.open(fileUrl, '_blank');
          dispatch(addNewNotification(notification))
          deleteEntityFromTable(tableItem.partitionKey, tableItem.rowKey, tableSasToken);
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }

      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }

    if (attempt === maxAttempts) {
      console.error('Maximum retries reached, no Response found');
    }
  };

  if (extractLoading) {
    return <Preloader classes={['fullscreen']} />;
  }

  return (
    <OuterContainer>
      <StickyHeader>
        <StyledButton variant="contained" color="primary" onClick={goToAnnotator}>
          Back
        </StyledButton>
        <Typography variant='h5' sx={{ maxWidth: '850px' }}>{generatedFileName}</Typography>
        {/*  onClick={updateQueryParams} */}
        <StyledButton variant="contained" color="primary" onClick={onExtract}>
          Save to document
        </StyledButton>
      </StickyHeader>
      {sortedAnnotations.map((item, index) => <QuestionAnswerComponent key={item.labelId} annotation={item} index={index} />)}
    </OuterContainer>
  );
};
