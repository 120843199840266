import { Provider } from 'react-redux';
import { Routes, Route, useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { MsalProvider } from "@azure/msal-react";
import { type IPublicClientApplication } from "@azure/msal-browser";
import { Grid } from "@mui/material";
import { Modal, PageLayout } from "./components";
import { CustomNavigationClient } from "./utils";
import store from './store';


// Sample app imports
import { Home } from "./pages/Home";
import { LogIn } from './pages/Login';
import { Profile } from "./pages/Profile";
import { ReviewFinal } from "./pages/Review";
import Annotator from "./pages/Annotator";

import { DocumentPreview } from "./pages/Preview";

type AppProps = {
    pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    return (
      <MsalProvider instance={pca}>
        <AuthenticatedTemplate>
          <Provider store={store}>
            <PageLayout>
              <Grid container justifyContent="center" sx={{
                maxWidth: '1400px',
                margin: '0 auto'
              }}>
                <Pages />
                <Modal />
              </Grid>
            </PageLayout>
          </Provider>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <LogIn instance={pca} />
        </UnauthenticatedTemplate>
      </MsalProvider>
    );
}

function Pages() {
    return (
        <Routes>
            <Route path="/profile" element={<Profile />} />
            <Route path="/preview" element={<DocumentPreview />} />
            <Route path="/annotator" element={<Annotator />} />
            <Route path="/review-final" element={<ReviewFinal />} />
            <Route path="/" element={<Home />} />
        </Routes>
    );
}

export default App;