import { type FC } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Typography, Modal as MuiModal } from "@mui/material";
import { containerStyle, StyledButton } from "./components";
import { type RootState, deleteNotification } from "../../store/";

const Modal: FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state: RootState) => state.modal.notifications);
  const notification = notifications[0];

  return (
    <MuiModal open={!!notification}>
      <Box sx={containerStyle}>
        <Typography id="modal-modal-description">
          {notification}
        </Typography>
        <StyledButton variant="contained" color="primary" onClick={() => dispatch(deleteNotification())}>
          Ok
        </StyledButton>
      </Box>
    </MuiModal>
  );
};

export default Modal;