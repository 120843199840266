import { type FC } from 'react';
import { Typography } from "@mui/material";
import { MsalAuthenticationResult } from "@azure/msal-react";

const ErrorComponent: FC<MsalAuthenticationResult> = ({ error }) => {
  return (
    <Typography variant="h6">
      An Error Occurred: {error ? error.errorCode : "unknown error"}
    </Typography>
  );
};

export default ErrorComponent;