import { type FC } from 'react';
import type {PreloaderProps} from "./interfaces";
import './Preloader.css';

const Preloader: FC<PreloaderProps> = ({ classes }) => {
  const classesArray = classes?.length ? classes : [];
  const classList = ["preloader-container", ...classesArray].join(' ');

  return (
    <div className={classList}>
      <div className="loader-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div> 
  );
};

export default Preloader;
