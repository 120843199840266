import { type ReactElement } from "react";
import { createSlice } from "@reduxjs/toolkit";

interface ModalSlice {
  notifications: Array<string | ReactElement>;
}

const initialState: ModalSlice = {
  notifications: []
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    addNewNotification(state, action) {
      state.notifications = [...state.notifications, action.payload];
    },
    deleteNotification(state) {
      state.notifications = state.notifications.slice(1);
    }
  }
});

export const {
  addNewNotification,
  deleteNotification
} = modalSlice.actions;

export default modalSlice.reducer;