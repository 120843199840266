import { styled } from "@mui/system";
import { Container, Grid } from "@mui/material";

export const StyledContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  minHeight: '70vh',
});

export const StyledGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(5),
  paddingLeft: 0,
  paddingRight: 0,
}));

export const NoResultsContainer = styled(Container)({
  display: 'flex',
  width: '100%',
  height: '50vh',
  alignItems: 'center',
  justifyContent: 'center',
});