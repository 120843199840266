import { type FC } from "react";
import { List } from "@mui/material";
import { NameListItem, JobTitleListItem, MailListItem, LocationListItem, PhoneListItem } from "./components";
import type { ProfileDataProps } from "./interfaces";

const ProfileData: FC<ProfileDataProps> = ({ graphData }) => {
  return (
    <List className="profileData">
      <NameListItem name={graphData.displayName} />
      <JobTitleListItem jobTitle={graphData.jobTitle} />
      <MailListItem mail={graphData.mail} />
      <PhoneListItem phone={graphData.businessPhones[0]} />
      <LocationListItem location={graphData.officeLocation} />
    </List>
  );
};

export default ProfileData;