import { type FC } from "react";
import { Typography } from "@mui/material";

const Loading: FC = () => {
  return (
    <Typography variant="h6">
      Authentication in progress...
    </Typography>
  );
};

export default Loading;

