import { type FC } from "react";
import { styled } from "@mui/system";
import { Box, Modal, Typography } from "@mui/material";
import type { CustomModalProps } from "./interfaces";

export const StickyBox = styled(Box)({
  top: '0px',
  position: 'sticky',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

export const CenteredTypography = styled(Typography)({
  margin: 'auto',
  textAlign: 'center',
});

export const CustomModal: FC<CustomModalProps> = ({ open, onClose, children }) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '80%',
        margin: 'auto',
        marginTop: '20px',
        backgroundColor: '#FFF',
        border: '2px solid #000',
        boxShadow: 24,
        padding: '2px 40px 20px',
        overflow: 'auto',
        maxHeight: '90vh',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}
    >
      {children}
    </Box>
  </Modal>
);