import { createSlice } from "@reduxjs/toolkit";
import type { IAccountInfo } from "../../types";

interface UserSlice {
  account: IAccountInfo;
}

const initialState: UserSlice = {
  account: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAccount(state, action) {
      state.account = action.payload;
    },
  }
});

export const {
  setUserAccount
} = userSlice.actions;

export default userSlice.reducer;