import CryptoJS from "crypto-js";

import { QueueServiceClient } from '@azure/storage-queue';
import { TableClient, AzureSASCredential } from "@azure/data-tables";
import { BlobServiceClient } from '@azure/storage-blob'

const accountName = 'damvpncusbdhst';
const sasToken = 'sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2024-09-15T19:19:13Z&st=2024-08-02T11:19:13Z&spr=https,http&sig=X%2BXjtBhjARgOu8YbbSOzWgzsgJaLN5k5r0deeppAH2o%3D';
const tableName = 'DataTransfer';
const azureBlobUrl = `https://${accountName}.blob.core.windows.net`;
const azureTableUrl = `https://${accountName}.table.core.windows.net`;
const azureQueueUrl = `https://${accountName}.queue.core.windows.net`;

export interface DocumentContentRequest {
  Body: string;
  partitionKey: string;
  rowKey: string;
  Response?: string;
  [key: string]: any;
}

const queueServiceClient = new QueueServiceClient(
  `https://${accountName}.queue.core.windows.net?${sasToken}`
);

const tableClient = new TableClient(
  `https://${accountName}.table.core.windows.net`,
  tableName,
  new AzureSASCredential(sasToken)
);


const blobServiceClient = new BlobServiceClient(
  `https://${accountName}.blob.core.windows.net?${sasToken}`
);

export const addMessageToQueue = async (queueName: string, message: any, queueSasToken: string) => {
  const queueServiceClient = new QueueServiceClient(`${azureQueueUrl}?${queueSasToken}`);
  const queueClient = queueServiceClient.getQueueClient(queueName);

  try {
    const base64Message = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(message));

    await queueClient.sendMessage(base64Message);
  } catch (error) {
    console.error("Error adding message to queue:", error.message);
  }
};

export const addEntityToTable = async (entity: DocumentContentRequest, sasToken: string) => {
  try {
    const tableClient = new TableClient(azureTableUrl, tableName, new AzureSASCredential(sasToken));

    await tableClient.createEntity(entity);
  } catch (error) {
    console.error("Error adding entity to table:", error.message);
  }
};

export const getEntityFromTable = async (partitionKey: string, rowKey: string, sasToken: string) => {
  try {
    const tableClient = new TableClient(azureTableUrl, tableName, new AzureSASCredential(sasToken));
    
    return await tableClient.getEntity<DocumentContentRequest>(partitionKey, rowKey);
  } catch (error) {
    console.error("Error retrieving entity from table:", error.message);
  }
};

export const deleteEntityFromTable = async (partitionKey: string, rowKey: string, sasToken: string) => {
  const tableClient = new TableClient(azureTableUrl, tableName, new AzureSASCredential(sasToken));

  tableClient.deleteEntity(partitionKey, rowKey)
}

export const clearAnswersTableField = async(partitionKey: string, rowKey: string) => {
  const tableEntity = {
    partitionKey,
    rowKey,
    ResponseErrorMessage: '',
    ResponseStatusCode: ''
  }

  tableClient.updateEntity(tableEntity)
}

async function blobToString(blob: Blob): Promise<string> {
  return blob.text();
}

export const downloadBlob = async (containerName: string, blobName: string) => {
  try {
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);
    const downloadBlockBlobResponse = await blobClient.download();
    const blob = await downloadBlockBlobResponse.blobBody;
    const downloadedBlob = await blobToString(blob);

    containerClient.deleteBlob(blobName);

    return downloadedBlob;
  } catch (error) {
    console.error("Error downloading blob:", error.message);
  }
};


export const uploadDataToBlob = async (containerName: string, blobName: string, data: any, sasToken: string) => {
  try {
    const blobServiceClient = new BlobServiceClient(`${azureBlobUrl}?${sasToken}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const dataString = JSON.stringify(data); 
    const blob = new Blob([dataString], { type: 'application/json;charset=utf-8' });

    const uploadBlobResponse = await blockBlobClient.uploadData(blob);

    console.log("Blob was uploaded successfully. requestId: ", uploadBlobResponse.requestId);

    return uploadBlobResponse.requestId;
  } catch (error) {
    console.error("Error uploading blob:", error.message);

    throw error;
  }
};

export const uploadFileToBlob = async (containerName: string, blobName: string, file: any, sasToken: string): Promise<void> => {
  try {
    const blobServiceClient = new BlobServiceClient(`${azureBlobUrl}?${sasToken}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    await blockBlobClient.uploadData(file);
  } catch (error) {
    console.error("Error uploading blob:", error.message);

    throw error;
  }
}