import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocList } from '../../types/index'

interface SearchState {
  searchString: string;
  page: number;
  documentsList: DocList;
  loading: boolean;
  error: string | null;
  foundPreview?: string;
}

const initialState: SearchState = {
  searchString: '',
  page: 1,
  documentsList: { hits: null, total: undefined },
  loading: false,
  error: null,
  foundPreview: ''
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchState(state, action) {
      state.searchString = action.payload.searchString;
      state.page = action.payload.page;
      state.documentsList = action.payload.documentsList;
      state.loading = true;
      state.error = null;
    },
    clearSearchState(state, action) {
      state.searchString = '';
      state.page = 1;
      state.documentsList = action.payload;
      state.loading = true;
      state.error = null;
    },
  },
});

export const {
  setSearchState,
  clearSearchState
} = searchSlice.actions;

export default searchSlice.reducer;
