import { type FC, useMemo} from 'react';
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileWord, faFileExcel, faFilePdf, faFilePowerpoint } from "@fortawesome/free-solid-svg-icons";
import { ThemeProvider } from "@mui/material/styles";
import { StyledContainer, StyledGridItem, StyledOwnerTypography, StyledFileNameTypography } from './components';
import { theme } from "../../styles/theme";
import type { DocumentCardProps } from "./interfaces";

const DocumentCard: FC<DocumentCardProps> = ({
  fileName,
  fileOwner,
  lastModifiedDateTime,
  onClick,
}) => {
  const { icon, color } = useMemo(() => {
    let icon;
    let color;

    if (fileName.toLowerCase().includes('.xls')) {
      icon = faFileExcel;
      color = 'green';
    } else if (fileName.toLowerCase().includes('.doc')) {
      icon = faFileWord;
      color = 'blue';
    } else if (fileName.toLowerCase().includes('.pdf')) {
      icon = faFilePdf;
      color = 'red';
    } else if (fileName.toLowerCase().includes('.ppt')) {
      icon = faFilePowerpoint;
      color = 'rgb(30, 48, 80)';
    }

    return { icon, color }
  }, [fileName]);

  return (
    <ThemeProvider theme={theme}>
      <StyledGridItem onClick={onClick}>
        <FontAwesomeIcon icon={icon} size="2xl" color={color} />
        <StyledContainer disableGutters>
          <StyledFileNameTypography variant="subtitle1">
            {fileName}
          </StyledFileNameTypography>
          <StyledOwnerTypography variant="body2">
            Owner: {fileOwner}
          </StyledOwnerTypography>
          <Typography variant="body2">Last modified: {lastModifiedDateTime}</Typography>
        </StyledContainer>
      </StyledGridItem>
    </ThemeProvider>
  );
};

export default DocumentCard;