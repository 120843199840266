import { msalInstance } from '../index'; 
import { loginRequest, graphConfig } from '../authConfig';
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";

interface IApiOptions {
  method?: string;
  header?: {
    contentType: string;
  };
  body?: any;
}

export async function getAccountInfo(account: AccountInfo) {
  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
    });

    return response;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      const response = await msalInstance.acquireTokenPopup({
        ...loginRequest,
        account: account
      });

      return response;
    } else {
      throw error;
    }
  }
}

function createFetchOptions(token: string, callOptions?: IApiOptions): RequestInit {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);

  if (callOptions?.header) {
    headers.append('content-type', callOptions.header.contentType);
  }

  const options: RequestInit = {
    method: callOptions?.method || "GET",
    headers: headers
  };

  if (callOptions?.body) {
    options.body = callOptions.body;
  }

  return options;
}

export async function callMsGraph(url?: string, callOptions?: IApiOptions): Promise<any> {
  const account: AccountInfo | null = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  try {
    const accountInfo = await getAccountInfo(account);
    const options = createFetchOptions(accountInfo.accessToken, callOptions);

    const result = await fetch(url || graphConfig.graphMeEndpoint, options);
    if (!result.ok) {
      throw new Error(`Error: ${result.status} ${result.statusText}`);
    }
    return result.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}
