import { type FC } from 'react';
import { Pagination, Typography } from "@mui/material";
import { NoResultsContainer, StyledContainer, StyledGrid } from './components';
import DocumentCard from "../DocumentCard";
import type { DocItem } from "../../types";
import type { DocumentsListProps } from './interfaces';

const DocumentsList: FC<DocumentsListProps> = ({
  list,
  loading,
  pageNumber,
  updatePage,
  getDocContents
}) => {
  if (list.total === undefined) return null;

  if (list.total === 0) {
    return (
      <NoResultsContainer>
        <Typography variant="h5">No results found</Typography>
      </NoResultsContainer>
    )
  }

  const pagesCount = Math.floor((list.total - 1) / 15);

  return (
    <StyledContainer disableGutters>
      <StyledGrid container>
        {list && list.hits.map((item: DocItem) => {
          const lastModifiedDate = new Date(item.resource.lastModifiedDateTime);

          return (
            <DocumentCard
              key={item.hitId}
              onClick={() => getDocContents(item)}
              fileName={item.resource.name}
              fileOwner={item.resource.createdBy.user.displayName}
              lastModifiedDateTime={lastModifiedDate.toDateString()}
            />
          )
        })}
      </StyledGrid>
      {pagesCount > 1 &&
          <Pagination
              page={pageNumber}
              count={pagesCount}
              disabled={loading}
              onChange={updatePage}
              sx={{ paddingTop: '20px', marginBottom: '30px' }}
              showFirstButton
              showLastButton
          />}
    </StyledContainer>
  );
};

export default DocumentsList;