import { styled } from "@mui/system";
import { Container, Grid, Typography } from "@mui/material";

export const StyledGridItem = styled(Grid)({
  display: 'flex',
  maxWidth: '33%',
  width: '33%',
  overflow: 'hidden',
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  border: '1px solid black',
  borderRadius: '5px',
  margin: '0 2px 10px',
  padding: '0 10px',
  cursor: 'pointer',
  maxHeight: '110px',
  '&:hover': {
    boxShadow: '5px 5px 10px 0px rgba(7, 26, 67,0.75)',
    background: 'rgba(7, 26, 67,0.1)',
  },
});

export const StyledContainer = styled(Container)({
  maxWidth: '90%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'column',
  padding: '0 10px',
  '@media (min-width: 1200px)': {
    maxWidth: '90%',
  },
});

export const StyledFileNameTypography = styled(Typography)({
  width: '100%',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  lineHeight: '1.4',
  wordWrap: 'break-word',
});

export const StyledOwnerTypography = styled(Typography)({
  width: '100%',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});